import { setItem } from "@/utils/localStorageControl.js";
export default function auth({ to, next, store }) {
  const userApps = store.getters.loggedUser.subAppCodes;
  const routeAppIds = to.meta.appIds;
  if (store.getters.isLoggedIn) {
    next();
    if (routeAppIds.some((i) => userApps.includes(i))) {
      next();
      return;
    } else {
      setItem("selectedApp", userApps[0]);
      window.location.reload();
      return;
    }
  }
  next("/login?redirected_from=" + to.fullPath);
}
